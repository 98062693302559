import { defineMessages } from 'react-intl';
import { addSchemaField } from 'design-comuni-plone-theme/config/Blocks/ListingOptions';
//import config from '@plone/volto/registry';

const messages = defineMessages({
  start_from: {
    id: 'Skip first N items',
    defaultMessage: 'N° di elementi da saltare',
  },
  show_reparto: {
    id: 'Show related department',
    defaultMessage: 'Mostra i reparti correlati',
  },
});

/** DEFAULT **/

const addDefaultAdditionalOptions = (schema, formData, intl, position = 0) => {
  let pos = position;
  addSchemaField(
    schema,
    'start_from',
    intl.formatMessage(messages.start_from),
    null,
    { type: 'number', default: null },
    pos,
  );
  pos++;

  if (
    formData.variation === 'simpleCard' ||
    formData.variation === 'cardWithImageTemplate' ||
    formData.variation === 'inEvidenceTemplate' ||
    formData.variation === 'ribbonCardTemplate' ||
    formData.variation === 'cardSlideUpTextTemplate' ||
    formData.variation === 'cardWithSideImageTemplate'
  ) {
    addSchemaField(
      schema,
      'show_reparto',
      intl.formatMessage(messages.show_reparto),
      null,
      { type: 'boolean', default: false },
      pos,
    );
    pos++;
  }

  return pos;
};

export default addDefaultAdditionalOptions;
