/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import {
  Container,
  Row,
  Col,
  Input,
} from 'design-react-kit/dist/design-react-kit';
import { ListingLinkMore } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  incarico_dirigente: {
    id: 'incarico_dirigente',
    defaultMessage: 'Incarico',
  },
  grado: {
    id: 'grado',
    defaultMessage: 'Grado',
  },
  cognome: {
    id: 'cognome',
    defaultMessage: 'Cognome',
  },
  nome: {
    id: 'nome',
    defaultMessage: 'Nome',
  },
  provvedimento: {
    id: 'provvedimento',
    defaultMessage: 'Provvedimento di attribuzione',
  },
  allegati_dirigente: {
    id: 'allegati_dirigente',
    defaultMessage: 'Allegati',
  },
  cv_dirigente: {
    id: 'cv_dirigente',
    defaultMessage: 'Curriculum vitae',
  },
  filtra: {
    id: 'collaborationtable_filtra',
    defaultMessage: 'Filtra',
  },
  no_items_found: {
    id: 'collaborationtable_no_items_found',
    defaultMessage: 'Nessun risultato trovato',
  },
});

const DirigentiTableTemplate = ({
  items,
  title,
  description,
  isEditMode,
  linkTitle,
  linkHref,
  addFilters,
  additionalFilters = [],
}) => {
  const intl = useIntl();
  const SEARCH_FIELD = 'SearchableText';

  const [search, setSearch] = useState(
    additionalFilters
      ?.filter((f) => {
        return f.i === SEARCH_FIELD;
      })?.[0]
      ?.v?.replace('*', '') || '',
  );

  const currQuerySearch =
    additionalFilters
      ?.filter((f) => {
        return f.i === SEARCH_FIELD;
      })?.[0]
      ?.v?.replace('*', '') || '';

  const useDebouncedEffect = (effect, delay, deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps);

    useEffect(() => {
      const handler = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [callback, delay]);
  };

  const addFilter = (f) => {
    let filters = [];
    if (f?.length > 0) {
      filters = [
        {
          i: SEARCH_FIELD,
          o: 'plone.app.querystring.operation.string.contains',
          v: f?.toLowerCase() + '*',
        },
      ];
    }

    addFilters(filters);
  };

  useDebouncedEffect(
    () => {
      if (currQuerySearch !== search) {
        addFilter(search);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    600,
    [search],
  );

  return (
    <div className="dirigenti-table-template">
      <Container className="px-4">
        {title && (
          <Row>
            <Col>
              <h2 className="mb-4">{title}</h2>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6} className="offset-md-6">
            <Input
              autoFocus={currQuerySearch?.length > 0}
              id="search"
              label={intl.formatMessage(messages.filtra)}
              type="text"
              className="m-2"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounce(() => addFilter(e.target.value), 500);
              }}
            />
          </Col>
        </Row>
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-sm">
            <thead>
              <tr className="table-primary">
                <th>{intl.formatMessage(messages.incarico_dirigente)}</th>
                <th>{intl.formatMessage(messages.grado)}</th>
                <th>{intl.formatMessage(messages.cognome)}</th>
                <th>{intl.formatMessage(messages.nome)}</th>
                <th>{intl.formatMessage(messages.provvedimento)}</th>
                <th>{intl.formatMessage(messages.allegati_dirigente)}</th>
              </tr>
            </thead>
            <tbody>
              {(items?.length === 0 || !items) && (
                <tr>
                  <td colSpan="9" className="text-center">
                    {intl.formatMessage(messages.no_items_found)}
                  </td>
                </tr>
              )}
              {items.map((item, index) => {
                return (
                  <tr key={'dirigenti' + index}>
                    <td>{item?.dirigente_incarico}</td>
                    <td>{item?.dirigente_grado}</td>
                    <td>{item?.dirigente_cognome}</td>
                    <td>{item?.dirigente_nome}</td>
                    <td>{item?.dirigente_provvedimento_attribuzione}</td>
                    <td>
                      <ul>
                        {/* Curriculum vitae */}
                        {item?.curriculum_vitae?.download && (
                          <li className="item-curriculum">
                            {intl.formatMessage(messages.cv_dirigente)}:{' '}
                            <a
                              href={flattenToAppURL(
                                item.curriculum_vitae.download,
                              )}
                              title={item.curriculum_vitae.filename}
                            >
                              {item.curriculum_vitae.filename}
                            </a>
                          </li>
                        )}
                        {/* Allegati */}
                        {item?.dirigente_allegati?.length > 0 &&
                          item.dirigente_allegati.map((allegato) => {
                            return (
                              <li className="item-attachments">
                                <a
                                  href={flattenToAppURL(allegato.download)}
                                  title={allegato.filename}
                                >
                                  {allegato.filename}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </Container>
    </div>
  );
};

DirigentiTableTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default DirigentiTableTemplate;
