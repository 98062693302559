import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Container, Row, Col } from 'design-react-kit/dist/design-react-kit';
import { VenuesSmall } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  reparti_correlati: {
    id: 'reparti_correlati',
    defaultMessage: 'Reparti correlati',
  },
});

const AfterContent = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {content.reparti_correlati && content.reparti_correlati?.length > 0 && (
        <section id="reparti-correlati">
          <section className="section">
            <div className="section-content">
              <Container>
                <Row>
                  <Col className="text-center mb-3">
                    <h3>{intl.formatMessage(messages.reparti_correlati)}</h3>
                  </Col>
                </Row>
                <VenuesSmall
                  venues={content.reparti_correlati}
                  show_icon="it-pa"
                />
              </Container>
            </div>
          </section>
        </section>
      )}
    </>
  );
};
export default AfterContent;
