/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@italia/reducers';
import { searchRepartiFiltersReducer } from './searchRepartiFilters';

/**
 * Root reducer.
 * @function
 * @param {Object} state Current state.
 * @param {Object} action Action to be handled.
 * @returns {Object} New state.
 */
const reducers = {
  ...defaultReducers,
  searchRepartiFilters: searchRepartiFiltersReducer,
};

export default reducers;
