/**
 * HeaderSlim component.
 * @module components/ItaliaTheme/Header/HeaderSlim
 */

/*
 ------CUSTOMIZATIONS: 
 - show ArLogin only if isn't subsite
 - added social links
 */

import React from 'react';
import { useSelector } from 'react-redux';
import {
  ParentSiteMenu,
  LanguageSelector,
  ArLogin,
  TertiaryMenu,
  SocialHeader,
} from 'design-comuni-plone-theme/components/ItaliaTheme';

const HeaderSlimRightZone = () => {
  const subsite = useSelector((state) => state.subsite?.data);
  return (
    <>
      <SocialHeader />
      <ParentSiteMenu />
      {!subsite && <TertiaryMenu />}
      <LanguageSelector />
      {!subsite && <ArLogin />}
    </>
  );
};

export default HeaderSlimRightZone;
