/*
Queto file contiene le estensioni per le funzioni presenti in ListingHelper.js, in modo da poter definire comportamenti diversi nel proprio progetto.
Sovrascrivere questo file nel progetto figlio ed implementare le proprie estensioni.*/
import React from 'react';
import { viewDate } from 'design-comuni-plone-theme/helpers';
import { useIntl } from 'react-intl';

const Intl = () => {
  const intl = useIntl();
  return intl;
};

const getCalendarDate_extend = (item) => {
  const intl = Intl();
  let date = null;

  const effective = item.effective && (
    <span>{viewDate(intl.locale, item.effective, 'D MMMM YYYY')}</span>
  );

  const showEffective = ['Storia', 'News Item'];
  if (showEffective.indexOf(item['@type']) >= 0) {
    date = effective;
  }
  return date;
};

const getEventRecurrenceMore_extend = (item, isEditMode) => {
  let ret = null;
  return ret;
};
export { getCalendarDate_extend, getEventRecurrenceMore_extend };
