/*
----------- CUSTOMIZATIONS: ------------
- rimosso l'orario dalle date di effective e scadenza_domande_bando
*/

import { defineMessages, useIntl } from 'react-intl';
import React from 'react';

import {
  Card,
  CardTitle,
  CardBody,
} from 'design-react-kit/dist/design-react-kit';
import PropTypes from 'prop-types';

import { viewDate } from 'design-comuni-plone-theme/helpers';

const messages = defineMessages({
  effective: {
    id: 'bando_effective',
    defaultMessage: 'Data di pubblicazione',
  },
  scadenza_bando: {
    id: 'scadenza_bando',
    defaultMessage: 'Scadenza dei termini per partecipare al bando',
  },
  scadenza_domande_bando: {
    id: 'scadenza_domande_bando',
    defaultMessage: 'Termine per le richieste di chiarimenti',
  },
  chiusura_procedimento_bando: {
    id: 'chiusura_procedimento_bando',
    defaultMessage: 'Chiusura del procedimento',
  },
  guri_date: {
    id: 'guri_date',
    defaultMessage: 'Pubblicazione su GURI',
  },
  guue_date: {
    id: 'guue_date',
    defaultMessage: 'Pubblicazione su GUUE',
  },
});

/**
 * BandoDates view component class.
 * @function BandoDates
 * @params {object} Dates: object.
 * @returns {string} Markup of the component.
 */
const BandoDates = ({ content }) => {
  const intl = useIntl();

  const effective = content?.effective
    ? viewDate(intl.locale, content.effective)
    : null;

  const scadenza_domande_bando = content?.scadenza_domande_bando
    ? viewDate(intl.locale, content.scadenza_domande_bando)
    : null;

  const scadenza_bando = content?.scadenza_bando
    ? viewDate(intl.locale, content.scadenza_bando)
    : null;

  const chiusura_procedimento_bando = content?.chiusura_procedimento_bando
    ? viewDate(intl.locale, content.chiusura_procedimento_bando)
    : null;

  const guri_date = content?.guri_date
    ? viewDate(intl.locale, content.guri_date)
    : null;
  const guue_date = content?.guue_date
    ? viewDate(intl.locale, content.guue_date)
    : null;

  let dates = [];
  if (effective) {
    dates.push({
      date: effective,
      title: intl.formatMessage(messages.effective),
    });
  }
  if (scadenza_domande_bando) {
    let time = scadenza_domande_bando.format('HH:mm');
    dates.push({
      date: scadenza_domande_bando,
      title:
        intl.formatMessage(messages.scadenza_domande_bando) +
        (time !== '23:55' ? ' - ' + time : ''),
    });
  }

  if (scadenza_bando) {
    let time = scadenza_bando.format('HH:mm');
    dates.push({
      date: scadenza_bando,
      title:
        intl.formatMessage(messages.scadenza_bando) +
        (time !== '23:55' ? ' - ' + time : ''),
    });
  }

  if (chiusura_procedimento_bando) {
    dates.push({
      date: chiusura_procedimento_bando,
      title: intl.formatMessage(messages.chiusura_procedimento_bando),
    });
  }

  if (guri_date) {
    dates.push({
      date: guri_date,
      title: intl.formatMessage(messages.guri_date),
    });
  }

  if (guue_date) {
    dates.push({
      date: guue_date,
      title: intl.formatMessage(messages.guue_date),
    });
  }

  dates.sort((d1, d2) => {
    if (d1.date.isBefore(d2.date)) {
      return -1;
    }
    if (d1.date.isAfter(d2.date)) {
      return 1;
    }
    return 0;
  });

  return content ? (
    <div className="point-list-wrapper my-4 mb-5">
      {dates.map((d, index) => (
        <div className="point-list" key={index}>
          <div className="point-list-aside point-list-warning">
            <div className="point-date text-monospace">
              {d.date.format('DD')}
            </div>
            <div className="point-month text-monospace">
              {d.date.format('MMM')}/{d.date.format('YY')}
            </div>
          </div>
          <div className="point-list-content">
            <Card
              className="card card-teaser rounded shadow"
              noWrapper={true}
              tag="div"
            >
              <CardBody tag="div" className={'card-body'}>
                <CardTitle tag="h5">{d.title}</CardTitle>
              </CardBody>
            </Card>
          </div>
        </div>
      ))}
    </div>
  ) : null;
};

export default BandoDates;

BandoDates.propTypes = {
  content: PropTypes.object.isRequired,
};
