/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { debounce } from 'lodash';
import {
  Container,
  Row,
  Col,
  Input,
} from 'design-react-kit/dist/design-react-kit';
import { ListingLinkMore } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { viewDate } from 'design-comuni-plone-theme/helpers';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  affidatario: {
    id: 'collaborationtable_affidatario',
    defaultMessage: 'Soggetto Affidatario (ragione sociale o generalità)',
  },

  oggetto_e_reparto: {
    id: 'collaborationtable_oggetto_e_reparto',
    defaultMessage: "Oggetto dell'incarico e Reparto fruitore",
  },
  durata_e_estremi: {
    id: 'collaborationtable_durata_e_estremi',
    defaultMessage: 'Durata incarico ed estremi atto conferimento',
  },
  compenso: {
    id: 'collaborationtable_compenso',
    defaultMessage: 'Compenso Previsto (presunto lordo)',
  },
  norma: {
    id: 'collaborationtable_norma',
    defaultMessage: "Norma o titolo a base dell'attribuzione",
  },
  modalita_beneficiario: {
    id: 'collaborationtable_modalita_beneficiario',
    defaultMessage: 'Modalità di individuazione del beneficiario',
  },
  ufficio_responsabile: {
    id: 'collaborationtable_ufficio_responsabile',
    defaultMessage:
      'Ufficio e Funzionario/Dirigente responsabile del procedimento',
  },
  allegati: {
    id: 'collaborationtable_allegati',
    defaultMessage:
      'Allegati:<ul><li>Contratto</li><li>Curriculum</li><li>Capitolato</li><li>Attestazione <sup>1</sup></li><li>Dichiarazione <sup>2</sup></li></ul>',
  },

  note: {
    id: 'collaborationtable_note',
    defaultMessage: 'Note',
  },

  note_text: {
    id: 'collaborationtable_note_text',
    defaultMessage:
      '<ol><li>Attestazione di verifica dell’insussistenza di situazioni di conflitto di interesse;</li><li>Dichiarazione di insussistenza delle cause di inconferibilità e incompatibilità, di situazioni, anche potenziali, di conflitto di interessi. Comunicazione dei dati relativi allo svolgimento di incarichi o la titolarità di cariche in enti di diritto privato regolati o finanziati dalla Pubblica Amministrazione ovvero allo svolgimento di attività professionali.</li></ol>',
  },

  durata_incarico_dal: {
    id: 'consulenza_durata_incarico_dal',
    defaultMessage: 'dal',
  },

  durata_incarico_al: {
    id: 'consulenza_durata_incarico_al',
    defaultMessage: 'al',
  },

  filtra: {
    id: 'collaborationtable_filtra',
    defaultMessage: 'Filtra',
  },
  no_items_found: {
    id: 'collaborationtable_no_items_found',
    defaultMessage: 'Nessun risultato trovato',
  },
});

const CollaborationTableTemplate = ({
  items,
  title,
  description,
  isEditMode,
  linkTitle,
  linkHref,
  addFilters,
  additionalFilters = [],
}) => {
  const intl = useIntl();
  const SEARCH_FIELD = 'searchableConsulenza';

  const [search, setSearch] = useState(
    additionalFilters
      ?.filter((f) => {
        return f.i === SEARCH_FIELD;
      })?.[0]
      ?.v?.replace('*', '') || '',
  );

  const currQuerySearch =
    additionalFilters
      ?.filter((f) => {
        return f.i === SEARCH_FIELD;
      })?.[0]
      ?.v?.replace('*', '') || '';

  const useDebouncedEffect = (effect, delay, deps) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const callback = useCallback(effect, deps);

    useEffect(() => {
      const handler = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }, [callback, delay]);
  };

  const addFilter = (f) => {
    let filters = [];
    if (f?.length > 0) {
      filters = [
        {
          i: SEARCH_FIELD,
          o: 'plone.app.querystring.operation.string.contains',
          v: f?.toLowerCase() + '*',
        },
      ];
    }

    addFilters(filters);
  };

  useDebouncedEffect(
    () => {
      if (currQuerySearch !== search) {
        addFilter(search);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    600,
    [search],
  );

  return (
    <div className="collaboration-table-template">
      <Container className="px-4 ">
        {/* {title && (
          <Row>
            <Col>
              <h2 className="mb-4">{title}</h2>
            </Col>
          </Row>
        )}

        {description && (
          <Row>
            <Col>
              <div className="description mb-4">{description}</div>
            </Col>
          </Row>
        )} */}
        <Row>
          <Col md={6} className="offset-md-6">
            <Input
              autoFocus={currQuerySearch?.length > 0}
              id="search"
              label={intl.formatMessage(messages.filtra)}
              type="text"
              className="m-2"
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
                debounce(() => addFilter(e.target.value), 500);
              }}
            />
          </Col>
        </Row>
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-sm">
            <caption>
              <span className="font-weight-bold">
                {intl.formatMessage(messages.note)}
              </span>
              <hr />
              <div
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage(messages.note_text),
                }}
              />
            </caption>
            <thead>
              <tr className="table-primary">
                <th>{intl.formatMessage(messages.affidatario)}</th>
                <th>{intl.formatMessage(messages.oggetto_e_reparto)}</th>
                <th>{intl.formatMessage(messages.durata_e_estremi)}</th>
                <th>{intl.formatMessage(messages.compenso)}</th>
                <th>{intl.formatMessage(messages.norma)}</th>
                <th>{intl.formatMessage(messages.modalita_beneficiario)}</th>
                <th>{intl.formatMessage(messages.ufficio_responsabile)}</th>
                <th
                  className="allegati-col"
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(messages.allegati),
                  }}
                ></th>
              </tr>
            </thead>
            <tbody>
              {(items?.length === 0 || !items) && (
                <tr>
                  <td colSpan="9" className="text-center">
                    {intl.formatMessage(messages.no_items_found)}
                  </td>
                </tr>
              )}
              {items.map((item, index) => {
                return (
                  <tr key={'collaboration' + index}>
                    <td>
                      <UniversalLink item={item}>
                        {item.intestatario}
                      </UniversalLink>
                    </td>
                    <td>{item.oggetto_rapporto}</td>
                    <td>
                      {(item?.data_inizio_incarico ||
                        item.data_fine_incarico) && (
                        <div className="durata_incarico">
                          {item.data_inizio_incarico && (
                            <>
                              {intl.formatMessage(messages.durata_incarico_dal)}{' '}
                              {viewDate(
                                intl.locale,
                                item.data_inizio_incarico,
                                'DD/MM/YYYY',
                              )}{' '}
                            </>
                          )}
                          {item.data_fine_incarico && (
                            <>
                              {intl.formatMessage(messages.durata_incarico_al)}{' '}
                              {viewDate(
                                intl.locale,
                                item.data_fine_incarico,
                                'DD/MM/YYYY',
                              )}
                            </>
                          )}
                          {item.estremi_atto && (
                            <>
                              <hr />
                              {item.estremi_atto}
                            </>
                          )}
                        </div>
                      )}
                    </td>
                    <td>{item.importo}</td>
                    <td>{item.norma_attribuzione}</td>
                    <td>{item.modalita_individuazione_beneficiario?.title}</td>
                    <td>{item.ufficio_responsabile_procedimento?.title}</td>
                    <td>
                      {item.items && (
                        <ul>
                          {item.items.map((att, _i) => (
                            <li>
                              <a
                                href={flattenToAppURL(
                                  `${att['@id']}/@@download/file`,
                                )}
                              >
                                {att.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>

        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </Container>
    </div>
  );
};

CollaborationTableTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default CollaborationTableTemplate;
