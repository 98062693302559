import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=210';

const LogoFooter = () => (
  <figure className="icon">
    <img src={logo} width="206" height="85" alt="Stemma Guardia di Finanza" />
  </figure>
);

export default LogoFooter;
