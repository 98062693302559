import { defineMessages } from 'react-intl';

import { addSchemaField } from 'design-comuni-plone-theme/config/Blocks/ListingOptions';

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
});

/** CARD WITH SIDE IMAGE TEMPLATE **/

export const addCardWithSideImageTemplateOptions = (
  schema,
  formData,
  intl,
  position = 0,
) => {
  let pos = position;
  addSchemaField(
    schema,
    'description',
    intl.formatMessage(messages.description),
    null,
    { widget: 'textarea' },
    pos,
  );
  pos++;

  return pos;
};
