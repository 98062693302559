/*
Queto file contiene le estensioni per le funzioni presenti in common.js, in modo da poter definire comportamenti diversi nel proprio progetto.
Sovrascrivere questo file nel progetto figlio ed implementare le proprie estensioni.*/

const getItemIcon_extend = (item) => {
  const type_icons = {
    Storia: 'it-bookmark',
  };
  let icon = type_icons[item['@type']] || 'it-pa'; //default-icon

  return icon;
};

export default getItemIcon_extend;
