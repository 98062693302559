/*
----------- CUSTOMIZATIONS: ------------
- aggiunti telefono, email e pec nella card dell'ufficio
*/

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UniversalLink } from '@plone/volto/components';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { getContent, resetContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { RichText } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import ContactLink from 'design-comuni-plone-theme/components/ItaliaTheme/View/Commons/ContactLink';

/**
 * OfficeCard view component class.
 * @function OfficeCard
 * @params {object} content: Content object.
 * @returns {string} Markup of the component.
 */
const OfficeCard = ({
  office,
  extended,
  icon,
  children,
  margin_bottom = false,
}) => {
  const key = `${office['@id']}_office`;
  const url = flattenToAppURL(office['@id']);
  const officeContent = useSelector((state) => state.content.subrequests);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getContent(url, null, key));
    return () => dispatch(resetContent(key));
  }, [dispatch, office, url, key]);

  let office_fo = officeContent[key]?.data;

  let city = office_fo?.city ?? office_fo?.sede?.city;
  let zip_code = office_fo?.zip_code ?? office_fo?.sede?.zip_code;
  let street = office_fo?.street ?? office_fo?.sede?.street;
  let telefono = office_fo?.telefono ?? office_fo?.sede?.telefono;
  let email = office_fo?.email ?? office_fo?.sede?.email;
  let pec = office_fo?.pec ?? office_fo?.sede?.pec;

  return office_fo ? (
    <div
      className={cx('card card-teaser rounded shadow p-4', {
        'mb-3': margin_bottom,
      })}
    >
      {icon && <Icon icon={icon}></Icon>}
      <div className="card-body pr-3">
        <h5 className="card-title">
          <UniversalLink item={office_fo} title={office_fo.title}>
            {office_fo.title}
          </UniversalLink>
        </h5>
        <p className="card-text">{office_fo.description}</p>

        {(city || zip_code || street || telefono || email || pec) && (
          <div className="card-text">
            {street && <p>{street}</p>}
            {(city || zip_code) && (
              <p>
                {zip_code} {city}
              </p>
            )}

            {extended ? (
              <>
                <RichText
                  serif={false}
                  add_class="card-text"
                  content={office_fo.contact_info}
                />
              </>
            ) : null}

            {(telefono || email || pec) && (
              <div className="mt-3">
                {telefono && (
                  <div>
                    <ContactLink tel={telefono} label={true} />
                  </div>
                )}
                {email && (
                  <div>
                    <ContactLink email={email} label={true} />
                  </div>
                )}
                {pec && (
                  <div>
                    PEC: <ContactLink email={pec} label={false} />
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        {children && <div className="card-text">{children}</div>}
      </div>
    </div>
  ) : null;
};
export default OfficeCard;

OfficeCard.propTypes = {
  office: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    description: PropTypes.string,
    title: PropTypes.string,
    review_state: PropTypes.string,
  }),
  extended: PropTypes.bool,
  icon: PropTypes.string,
};
