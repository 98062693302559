/*
Questo file consente di customizzare l'header dei CT per aggiungere dati o customizzare l'header dei CT creati ad hoc nel proprio progetto.
Sovrascrivere questo file nel progetto figlio ed implementare la propria logica per l'estensionde dell'header.

-- non più utilizzato nel CT Reparto, lascio come esempio per future customizzazioni
*/

import PropTypes from 'prop-types';

const PageHeaderExtend = (props) => {
  //const { content } = props;

  /*if (content['@type'] === 'Reparto') {
    return <PageHeaderReparto {...props} />;
  }*/
  return null;
};
export default PageHeaderExtend;

PageHeaderExtend.propTypes = {
  params: PropTypes.shape({
    content: PropTypes.object,
    readingtime: PropTypes.string,
    showreadingtime: PropTypes.bool,
    imageinheader: PropTypes.bool,
    imageinheader_field: PropTypes.string,
    showdates: PropTypes.bool,
    showtassonomiaargomenti: PropTypes.bool,
  }),
};
