import React, { useState, useEffect, createRef } from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button } from 'design-react-kit/dist/design-react-kit';
import moment from 'moment';
import cx from 'classnames';
import { OSMMap } from 'volto-venue';
import { searchContent, resetSearchContent } from '@plone/volto/actions';
import { flattenToAppURL } from '@plone/volto/helpers';

import { SelectInput } from 'design-comuni-plone-theme/components';
import { getSearchRepartiFilters } from '@package/actions';

import Body from './Body';

const messages = defineMessages({
  find: {
    id: 'find',
    defaultMessage: 'Cerca',
  },
  noResult: {
    id: 'noResult',
    defaultMessage: 'Nessun risultato trovato',
  },
  search_keyword: {
    id: 'search_keyword',
    defaultMessage: 'Cerca per parola chiave',
  },
  region: {
    id: 'region',
    defaultMessage: 'Regione',
  },
  province: {
    id: 'province',
    defaultMessage: 'Provincia',
  },
  district: {
    id: 'district',
    defaultMessage: 'Comune',
  },
  centralino: {
    id: 'centralino',
    defaultMessage: 'Centralino',
  },
  email: {
    id: 'email',
    defineMessage: 'Email',
  },
  pec: {
    id: 'pec',
    defaultMessage: 'PEC',
  },
  view_on_googlemaps: {
    id: 'view_on_googlemaps',
    defaultMessage: 'Vedi su Google Maps',
  },
  view_on_bingmaps: {
    id: 'view_on_bingmaps',
    defaultMessage: 'Vedi su Bing Maps',
  },
  view_on_applemaps: {
    id: 'view_on_applemaps',
    defaultMessage: 'Vedi su Apple Maps',
  },
  connectionError: {
    id: 'connection_error',
    defaultMessage: 'Errore di connessione',
  },
  connectionErrorMessage: {
    id: 'connection_error_message',
    defaultMessage:
      'Il server non è raggiungibile a causa di un errore del server, di un timeout del server o di problemi di connessione di rete del tuo dispositivo.. Verifica di essere connesso alla rete e riprova.',
  },
});

const defaultFiltersState = {
  SearchableText: '',
  region: null,
  province: null,
  district: null,
};

const getOptionsFromList = (options) =>
  options
    ?.filter((opt) => opt !== null)
    ?.map((option) => ({ label: option, value: option }));

const getSelectValue = (val) => {
  if (val) return { label: val, value: val };
  else return null;
};

const getVenuesFromItems = (items, intl) =>
  items.reduce((acc, val) => {
    if (
      (val.latitude && val.longitude) ||
      (val.geolocation?.latitude && val.geolocation?.longitude)
    ) {
      return [
        ...acc,
        {
          latitude: val.latitude || val.geolocation.latitude,
          longitude: val.longitude || val.geolocation.longitude,
          title: val.title,
          popupContent: (
            <div className="reparto-pin-popup">
              <div className="title">{val.title}</div>
              <p>
                <a
                  href={`http://maps.google.com/?q=${val.street ?? ''} ${
                    val.zip_code ?? ''
                  } ${val.city ?? ''} ${val.province ?? ''} ${
                    val.geolocation.latitude
                  },${val.geolocation.longitude}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(messages.view_on_googlemaps)}
                </a>
              </p>
              <p>
                <a
                  href={`https://bing.com/maps/default.aspx?where1=${
                    val.street ?? ''
                  } ${val.zip_code ?? ''} ${val.city ?? ''} ${
                    val.province ?? ''
                  }`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(messages.view_on_bingmaps)}
                </a>
              </p>
              <p>
                <a
                  href={`  http://maps.apple.com/?q=${val.street ?? ''} ${
                    val.zip_code ?? ''
                  } ${val.city ?? ''} ${val.province ?? ''}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(messages.view_on_applemaps)}
                </a>
              </p>
            </div>
          ),
        },
      ];
    }

    return acc;
  }, []);

const View = ({ data, inEditMode, path, onChangeBlock }) => {
  const intl = useIntl();
  const b_size = 6;
  moment.locale(intl.locale);

  const [currentPage, setCurrentPage] = useState(1);
  const subsite = useSelector((state) => state.subsite?.data);

  const dispatch = useDispatch();

  const searchResults = useSelector((state) => {
    return state.search?.subrequests?.reparti_search;
  });

  const items = useSelector((state) => {
    return state.search?.subrequests?.reparti_search?.items ?? [];
  });
  const loading = useSelector((state) => {
    return state.search?.subrequests?.reparti_search?.loading || false;
  });

  const venues = useSelector((state) => {
    let items = state.search?.subrequests?.reparti_search_full?.items ?? [];
    return getVenuesFromItems(items, intl);
  });

  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
    return () => {
      dispatch(resetSearchContent('reparti_search'));
      dispatch(resetSearchContent('reparti_search_full'));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resultsRef = createRef();

  const [filters, setFilters] = useState(defaultFiltersState);
  const filtersData = useSelector(
    (state) => state.searchRepartiFilters?.result ?? {},
  );

  // const getQuery = useCallback(() => {
  const getQuery = () => {
    let query = {
      portal_type: ['Reparto'],
    };

    Object.keys(filters).forEach((key) => {
      if (['', null, undefined].indexOf(filters[key]) === -1) {
        query[key] =
          key === 'SearchableText' ? `${filters[key]}*` : filters[key];
      }
    });

    return query;
  };

  const doRequest = (page = currentPage) => {
    let query = getQuery();

    //search all map points
    dispatch(
      searchContent(
        subsite ? flattenToAppURL(subsite['@id']) : '',
        {
          ...query,
          metadata_fields: [
            'geolocation',
            'street',
            'zip_code',
            'city',
            'province',
          ],
          b_size: 10000,
        },
        'reparti_search_full',
      ),
    );

    //search results paginated list
    dispatch(
      searchContent(
        subsite ? flattenToAppURL(subsite['@id']) : '',
        {
          ...query,
          fullobjects: true,
          b_size: b_size,
          b_start: (page - 1) * b_size,
        },
        'reparti_search',
      ),
    );
  };

  useEffect(() => {
    let query = getQuery();
    if (query.SearchableText) delete query.SearchableText;
    dispatch(getSearchRepartiFilters(query));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.region, filters.province, filters.district, dispatch]);

  const handleQueryPaginationChange = (e, { activePage }) => {
    resultsRef?.current?.scrollIntoView &&
      resultsRef.current.scrollIntoView({ behavior: 'smooth' });
    const current = activePage?.children ?? 1;
    setCurrentPage(current);
    doRequest(current);
  };

  return (
    <div className={cx('block', data['@type'])}>
      <div className="public-ui">
        <Container>
          {data.title && <h2>{data.title}</h2>}
          <div
            className={cx('rounded bg-primary my-4 px-3 py-3', {
              'public-ui': inEditMode,
            })}
          >
            <div className="d-flex justify-content-center filters-container">
              <div className="d-flex search-container align-items-center justify-content-center flex-wrap">
                <div className="input-group filter-wrapper text-filter">
                  <input
                    id="SearchableText"
                    type="text"
                    className="form-control"
                    onChange={
                      inEditMode
                        ? () => {}
                        : (e) => {
                            setFilters({
                              ...filters,
                              SearchableText: e.target.value,
                            });
                          }
                    }
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        doRequest(1);
                      }
                    }}
                    value={filters.SearchableText}
                    disabled={loading}
                    placeholder={intl.formatMessage(messages.search_keyword)}
                  />
                </div>
                <div className="filter-wrapper select-filter">
                  <SelectInput
                    id="region"
                    onChange={
                      inEditMode
                        ? () => {}
                        : (opt) =>
                            setFilters({
                              ...filters,
                              region: opt?.value ?? null,
                            })
                    }
                    value={getSelectValue(filters.region)}
                    isDisabled={loading}
                    placeholder={intl.formatMessage(messages.region)}
                    options={getOptionsFromList(filtersData?.region ?? [])}
                    isClearable
                  />
                </div>
                <div className="filter-wrapper select-filter">
                  <SelectInput
                    id="province"
                    onChange={
                      inEditMode
                        ? () => {}
                        : (opt) =>
                            setFilters({
                              ...filters,
                              province: opt?.value ?? null,
                            })
                    }
                    value={getSelectValue(filters.province)}
                    isDisabled={loading}
                    options={getOptionsFromList(filtersData?.province ?? [])}
                    placeholder={intl.formatMessage(messages.province)}
                    isClearable
                  />
                </div>
                <div className="filter-wrapper select-filter">
                  <SelectInput
                    id="district"
                    onChange={
                      inEditMode
                        ? () => {}
                        : (opt) =>
                            setFilters({
                              ...filters,
                              district: opt?.value ?? null,
                            })
                    }
                    value={getSelectValue(filters.district)}
                    isDisabled={loading}
                    options={getOptionsFromList(filtersData?.district ?? [])}
                    placeholder={intl.formatMessage(messages.district)}
                    isClearable
                  />
                </div>
                <Button
                  color="tertiary"
                  icon={false}
                  tag="button"
                  onClick={() => doRequest(1)}
                  className="my-2 my-lg-1 mx-2"
                  disabled={inEditMode}
                >
                  {intl.formatMessage(messages.find)}
                </Button>
              </div>
            </div>
          </div>

          {loading ? (
            <div className="px-5">
              <Body
                items={[0, 1, 2, 3, 4, 5]}
                onPageChange={handleQueryPaginationChange}
                skeleton
                inEditMode={inEditMode}
              />
            </div>
          ) : items?.length > 0 ? (
            <div className="px-5 results-container">
              <Body
                items={items}
                total={searchResults?.total}
                currentPage={currentPage}
                onPageChange={handleQueryPaginationChange}
                inEditMode={inEditMode}
              />
              {isClient && venues.length > 0 && (
                <OSMMap markers={venues} showTooltip={true} showPopup={true} />
              )}
            </div>
          ) : searchResults ? (
            <div className="mt-4 px-5 results-container">
              <p className="text-center">
                {searchResults.error ? (
                  <>
                    <div>
                      <strong>
                        {intl.formatMessage(messages.connectionError)}
                      </strong>
                    </div>
                    <div>
                      {intl.formatMessage(messages.connectionErrorMessage)}
                      {/* <div className="small-text">
                          {searchResults.error.message}
                        </div> */}
                    </div>
                  </>
                ) : (
                  <>{intl.formatMessage(messages.noResult)}</>
                )}
              </p>
            </div>
          ) : data.defaultResults?.length > 0 ? (
            <div className="px-5 results-container">
              <Body
                items={data.defaultResults}
                onPageChange={handleQueryPaginationChange}
                inEditMode={inEditMode}
              />
              {isClient &&
                getVenuesFromItems(data.defaultResults, intl)?.length > 0 && (
                  <>
                    <OSMMap
                      markers={getVenuesFromItems(data.defaultResults, intl)}
                      showTooltip={true}
                      showPopup={true}
                    />
                  </>
                )}
            </div>
          ) : null}
        </Container>
      </div>
    </div>
  );
};

export default View;
