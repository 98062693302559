import React from 'react';
import PropTypes from 'prop-types';
import { Segment, Accordion } from 'semantic-ui-react';
import { defineMessages, useIntl } from 'react-intl';
import { ObjectBrowserWidget, TextWidget } from '@plone/volto/components';

const messages = defineMessages({
  default: {
    id: 'Ricerca reparti',
    defaultMessage: 'Ricerca reparti',
  },
  title: {
    id: 'title',
    defaultMessage: 'Title',
  },
  defaultResults: {
    id: 'defaultResults',
    defaultMessage: 'Default results',
  },
});

const Sidebar = ({ data, block, onChangeBlock }) => {
  const intl = useIntl();

  return (
    <Segment.Group raised key={block.id || block}>
      <header className="header pulled">
        <h2>{intl.formatMessage(messages.default)}</h2>
      </header>
      <Accordion className="form">
        <Accordion.Content active={true}>
          <TextWidget
            id="title"
            title={intl.formatMessage(messages.title)}
            value={data.title}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value,
              });
            }}
          />

          <ObjectBrowserWidget
            id="defaultResults"
            title={intl.formatMessage(messages.defaultResults)}
            value={data.defaultResults || []}
            mode="multiple"
            widgetOptions={{
              pattern_options: { selectableTypes: ['Reparto'] },
            }}
            onChange={(name, value) => {
              onChangeBlock(block, {
                ...data,
                [name]: value || [],
              });
            }}
          />
        </Accordion.Content>
      </Accordion>
    </Segment.Group>
  );
};

Sidebar.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  block: PropTypes.string,
  onChangeBlock: PropTypes.func,
  selected: PropTypes.any,
  setSelected: PropTypes.func,
};

export default Sidebar;
