import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardBody,
  CardText,
} from 'design-react-kit/dist/design-react-kit';

import cx from 'classnames';

import { UniversalLink } from '@plone/volto/components';
import { Pagination } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  find: {
    id: 'find',
    defaultMessage: 'Cerca',
  },
  noResult: {
    id: 'noResult',
    defaultMessage: 'Nessun risultato trovato',
  },
  search_keyword: {
    id: 'search_keyword',
    defaultMessage: 'Cerca per parola chiave',
  },
  region: {
    id: 'region',
    defaultMessage: 'Regione',
  },
  province: {
    id: 'province',
    defaultMessage: 'Provincia',
  },
  district: {
    id: 'district',
    defaultMessage: 'Comune',
  },
  centralino: {
    id: 'centralino',
    defaultMessage: 'Centralino',
  },
  email: {
    id: 'email',
    defineMessage: 'Email',
  },
  pec: {
    id: 'pec',
    defaultMessage: 'PEC',
  },
  totals: {
    id: 'Trovati {number} reparti',
    defaultMessage: 'Trovati {number} reparti',
  },
});

const Body = ({
  items,
  total = items?.length || 0,
  currentPage = 1,
  onPageChange,
  skeleton = false,
  inEditMode,
}) => {
  const intl = useIntl();
  const b_size = 6;
  return (
    <div className={cx('my-4', { 'skeleton-template': skeleton })}>
      {total > 0 && (
        <div className="totals">
          {intl.formatMessage(messages.totals, { number: total })}
        </div>
      )}
      <Row>
        {items.map((item) => (
          <Col md={4} key={item['@id']}>
            <Card
              className="align-items-top rounded shadow mt-3"
              noWrapper
              teaser
              key={skeleton ? item : item['@id']}
            >
              <CardBody>
                <CardTitle tag="h3">
                  {!skeleton && (
                    <UniversalLink
                      item={!inEditMode ? item : null}
                      href={inEditMode ? '#' : null}
                    >
                      {item.title}
                    </UniversalLink>
                  )}
                </CardTitle>
                {skeleton && <CardText />}
                {!skeleton && (
                  <CardText>
                    {item.street && <p>{item.street}</p>}
                    {(item.zip_code || item.city || item.province) && (
                      <p>
                        {item.zip_code}
                        {item.zip_code && (item.city || item.province)
                          ? ' - '
                          : ''}
                        {item.city}
                        {item.province && item.city
                          ? ` ${item.province}`
                          : item.province}
                      </p>
                    )}
                    {item.telefono && (
                      <p>
                        {intl.formatMessage(messages.centralino)}{' '}
                        {item.telefono}
                      </p>
                    )}
                    {item.email && (
                      <p>
                        {intl.formatMessage(messages.email)}:{' '}
                        <a href={`mailto:${item.email}`}>{item.email}</a>
                      </p>
                    )}
                    {item.pec && (
                      <p>
                        {intl.formatMessage(messages.pec)}:{' '}
                        <a href={`mailto:${item.pec}`}>{item.pec}</a>
                      </p>
                    )}
                  </CardText>
                )}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {total > b_size && (
        <Pagination
          activePage={currentPage}
          totalPages={Math.ceil(total / b_size)}
          onPageChange={onPageChange}
        />
      )}
      {skeleton && (
        <div className="img-skeleton mt-4" style={{ height: '300px' }} />
      )}
    </div>
  );
};

export default React.memo(Body);
