import React from 'react';
// eslint-disable-next-line import/no-unresolved
import logo from '~/components/Layout/img/logo.png?width=410';

const Logo = () => (
  <figure className="icon">
    <img src={logo} width="410" height="91" alt="Stemma Guardia di Finanza" />
  </figure>
);

export default Logo;
