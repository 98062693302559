import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ViewRicercaReparti from './View';
import { SidebarPortal } from '@plone/volto/components';
import Sidebar from './Sidebar';

const Edit = (props) => (
  <div className={cx('block', props.data['@type'])}>
    <ViewRicercaReparti {...props} />
    <SidebarPortal selected={props.selected}>
      <Sidebar {...props} />
    </SidebarPortal>
  </div>
);

Edit.propTypes = {
  properties: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  onChangeField: PropTypes.func.isRequired,
  onSelectBlock: PropTypes.func.isRequired,
  onDeleteBlock: PropTypes.func.isRequired,
  onAddBlock: PropTypes.func.isRequired,
  onFocusPreviousBlock: PropTypes.func.isRequired,
  onFocusNextBlock: PropTypes.func.isRequired,
  block: PropTypes.string.isRequired,
};

export default Edit;
