import { defineMessages } from 'react-intl';

import { addSchemaField } from 'design-comuni-plone-theme/config/Blocks/ListingOptions';

const messages = defineMessages({
  title: {
    id: 'title',
    defaultMessage: 'Titolo',
  },
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  show_reparto: {
    id: 'Show related department',
    defaultMessage: 'Mostra i reparti correlati',
  },
});

/** NO CARD TEMPLATE OPTIONS **/

export const addNoCardTemplateOptions = (
  schema,
  formData,
  intl,
  position = 0,
) => {
  let pos = position;
  addSchemaField(
    schema,
    'title',
    intl.formatMessage(messages.title),
    null,
    { widget: 'text' },
    pos,
  );
  pos++;
  addSchemaField(
    schema,
    'description',
    intl.formatMessage(messages.description),
    null,
    { widget: 'textarea' },
    pos,
  );
  pos++;
  addSchemaField(
    schema,
    'show_reparto',
    intl.formatMessage(messages.show_reparto),
    null,
    { type: 'boolean', default: false },
    pos,
  );
  pos++;

  return pos;
};
