export const GET_SEARCH_REPARTI_FILTERS = 'GET_SEARCH_REPARTI_FILTERS';

export function getSearchRepartiFilters(params) {
  return {
    type: GET_SEARCH_REPARTI_FILTERS,
    request: {
      op: 'get',
      path: '/@search-reparti-filters',
      params,
    },
  };
}
