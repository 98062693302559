import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from 'design-react-kit/dist/design-react-kit';
import {
  ListingLinkMore,
  ListingImage,
} from 'design-comuni-plone-theme/components/ItaliaTheme';

const CardWithSideImageTemplate = ({
  items,
  title,
  description,
  isEditMode,
  linkTitle,
  linkHref,
  show_block_bg,
}) => {
  return (
    <div className="card-with-side-image-template">
      <Container className="px-4 ">
        {title && (
          <Row>
            <Col>
              <h2 className="mb-4">{title}</h2>
            </Col>
          </Row>
        )}

        {description && (
          <Row>
            <Col>
              <div className="description mb-4">{description}</div>
            </Col>
          </Row>
        )}
        <Row className="items">
          {items.map((item, index) => {
            const image = ListingImage({ item });

            return (
              <Col md="6" lg="4" key={item['@id']} className="col-item px-2">
                <Card
                  className="card-bg rounded"
                  noWrapper={false}
                  space
                  tag="div"
                >
                  <CardBody>
                    <CardTitle tag="h3">
                      <UniversalLink
                        item={!isEditMode ? item : null}
                        href={isEditMode ? '#' : null}
                      >
                        {item.title}
                      </UniversalLink>
                    </CardTitle>
                    <CardText tag="p">{item.description}</CardText>
                  </CardBody>

                  {image && <div className="image-container">{image}</div>}
                </Card>
              </Col>
            );
          })}
        </Row>
        <ListingLinkMore title={linkTitle} href={linkHref} className="my-4" />
      </Container>
    </div>
  );
};

CardWithSideImageTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default CardWithSideImageTemplate;
