/**
 * DirigenteView view component.
 * @module components/theme/View/DirigenteView
 */

import React, { createRef, useEffect, useState } from 'react';
import { contentFolderHasItems } from 'design-comuni-plone-theme/helpers';
import { defineMessages, useIntl } from 'react-intl';
import {
  Attachment,
  Attachments,
  SideMenu,
  PageHeader,
  RichTextArticle,
  Metadata,
  Gallery,
  HelpBox,
  GenericCard,
  RelatedItems,
  RelatedItemInEvidence,
  richTextHasContent,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
} from 'design-react-kit/dist/design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  dati_anagrafici: {
    id: 'dati_anagrafici',
    defaultMessage: 'Dati anagrafici',
  },
  cognome_nome: {
    id: 'cognome_nome',
    defaultMessage: 'Cognome e nome',
  },
  documenti: {
    id: 'documenti',
    defaultMessage: 'Documenti',
  },
  grado: {
    id: 'grado',
    defaultMessage: 'Grado',
  },
  reparto: {
    id: 'reparto',
    defaultMessage: 'Reparto',
  },
  incarico_dirigente: {
    id: 'incarico_dirigente',
    defaultMessage: 'Incarico',
  },
  provvedimento: {
    id: 'provvedimento',
    defaultMessage: 'Provvedimento di attribuzione',
  },
  servizio_cessato: {
    id: 'servizio_cessato',
    defaultMessage: 'Servizio cessato',
  },
  servizio_attivo: {
    id: 'servizio_attivo',
    defaultMessage: 'Servizio attivo',
  },
  cv_dirigente: {
    id: 'cv_dirigente',
    defaultMessage: 'Curriculum vitae',
  },
  ulteriori_informazioni: {
    id: 'ulteriori_informazioni',
    defaultMessage: 'Ulteriori informazioni',
  },
  multimedia: {
    id: 'multimedia',
    defaultMessage: 'Multimedia',
  },
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
});

/**
 * DirigenteView view component class.
 * @function DirigenteView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const DirigenteView = ({ content }) => {
  const intl = useIntl();
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);

  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container px-4 my-4 persona-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          imageinheader={true}
          imageinheader_field={'foto_persona'}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        <div className="row border-top row-column-border row-column-menu-left">
          <aside className="col-lg-4">
            <SideMenu data={sideMenuElements} />
          </aside>
          <section
            className="col-lg-8 it-page-sections-container"
            id="main-content-section"
            ref={documentBody}
          >
            {/*** Anagrafica ***/}
            {(content?.nome || content?.cognome || content?.grado?.title) && (
              <RichTextArticle
                tag_id="dati_anagrafici"
                title={intl.formatMessage(messages.dati_anagrafici)}
              >
                {/* Nome e cognome */}
                <div className="mt-3 mb-4">
                  <h5>{intl.formatMessage(messages.cognome_nome)}</h5>
                  {content.cognome} {content.nome}
                </div>

                {/* Grado */}
                {content?.grado?.title && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.grado)}</h5>
                    {content.grado.title}
                  </div>
                )}
              </RichTextArticle>
            )}

            {/*** Incarico ***/}
            {(content?.incarico?.title ||
              content?.provvedimento_attribuzione ||
              content?.cessato) && (
              <RichTextArticle
                tag_id="incarico_dirigente"
                title={intl.formatMessage(messages.incarico_dirigente)}
              >
                <Card className="card card-teaser shadow mt-3 rounded mb-4">
                  <Icon icon={'it-box'} />
                  <CardBody>
                    {/* Incarico */}
                    {content?.incarico?.title && (
                      <CardTitle tag="h4" className="title-incarico">
                        {content.incarico.title}
                      </CardTitle>
                    )}
                    <CardText tag="p">
                      {/* Provvedimento */}
                      {content?.provvedimento_attribuzione && (
                        <>
                          {intl.formatMessage(messages.provvedimento)}:{' '}
                          <strong>{content.provvedimento_attribuzione}</strong>
                          <br />
                        </>
                      )}
                      {/* Servizio attuale */}
                      {content?.cessato === true ? (
                        <em>{intl.formatMessage(messages.servizio_cessato)}</em>
                      ) : (
                        <em>{intl.formatMessage(messages.servizio_attivo)}</em>
                      )}
                    </CardText>
                  </CardBody>
                </Card>
              </RichTextArticle>
            )}

            {/*** Reparto ***/}
            {content?.reparto?.length > 0 && (
              <RichTextArticle
                tag_id="reparto"
                title={intl.formatMessage(messages.reparto)}
              >
                {content?.reparto?.map((item, i) => (
                  <div className="reparto-info">
                    <GenericCard
                      key={item['@id']}
                      item={item}
                      show_icon="it-pin"
                    />
                  </div>
                ))}
              </RichTextArticle>
            )}

            {/*** Documenti ***/}
            {(content?.curriculum_vitae?.download ||
              contentFolderHasItems(content, 'documenti-allegati')) && (
              <RichTextArticle
                title={intl.formatMessage(messages.documenti)}
                tag_id="documenti"
              >
                {/* Curriculum */}
                {content.curriculum_vitae?.download && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.cv_dirigente)}</h5>
                    <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
                      <Attachment
                        download_url={content.curriculum_vitae.download}
                        title={content.curriculum_vitae.filename}
                      />
                    </div>
                  </div>
                )}
                {/* Allegati */}
                <Attachments
                  content={content}
                  folder_name={'documenti-allegati'}
                  as_article={false}
                  title={intl.formatMessage(messages.attachments)}
                />
              </RichTextArticle>
            )}

            {/*** Multimedia ***/}
            {contentFolderHasItems(content, 'multimedia') && (
              <RichTextArticle
                tag_id="multimedia"
                title={intl.formatMessage(messages.multimedia)}
              >
                <Gallery
                  content={content}
                  folder_name={'multimedia'}
                  title_type="h5"
                  className="mt-3"
                />
              </RichTextArticle>
            )}

            <Metadata content={content}>
              {richTextHasContent(content?.ulteriori_informazioni) && (
                <HelpBox text={content?.ulteriori_informazioni} />
              )}
            </Metadata>
          </section>
        </div>
      </div>

      <RelatedItems content={content} />
      <RelatedItemInEvidence content={content} />
    </>
  );
};

export default DirigenteView;
