/**
 * ConsulenzaView view component.
 * @module components/theme/View/ConsulenzaView
 */

import React, { createRef, useEffect, useState } from 'react';

import { defineMessages, useIntl } from 'react-intl';
import {
  Attachments,
  SideMenu,
  PageHeader,
  RichTextArticle,
  Metadata,
  HelpBox,
  RelatedItems,
  RelatedItemInEvidence,
  richTextHasContent,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { viewDate } from 'design-comuni-plone-theme/helpers';

const messages = defineMessages({
  beneficiario: {
    id: 'consulenza_beneficiario',
    defaultMessage: 'Beneficiario',
  },
  intestatario: {
    id: 'consulenza_intestatario',
    defaultMessage: 'Soggetto beneficiario',
  },
  modalita_individuazione_beneficiario: {
    id: 'consulenza_modalita_individuazione_beneficiario',
    defaultMessage: 'Modalità di individuazione del beneficiario',
  },
  norma_attribuzione: {
    id: 'consulenza_norma_attribuzione',
    defaultMessage: "Norma o titolo alla base dell'attribuzione",
  },
  incarico: {
    id: 'consulenza_incarico',
    defaultMessage: 'Incarico',
  },
  oggetto_rapporto: {
    id: 'consulenza_oggetto_rapporto',
    defaultMessage: "Ragione dell'incarico e Reparto fruitore",
  },
  durata_incarico: {
    id: 'consulenza_durata_incarico',
    defaultMessage: 'Durata incarico',
  },
  durata_incarico_dal: {
    id: 'consulenza_durata_incarico_dal',
    defaultMessage: 'dal',
  },
  durata_incarico_al: {
    id: 'consulenza_durata_incarico_al',
    defaultMessage: 'al',
  },
  importo: {
    id: 'consulenza_importo',
    defaultMessage: 'Importo',
  },
  atto_e_procedimento: {
    id: 'consulenza_atto_e_procedimento',
    defaultMessage: 'Atto e procedimento',
  },
  data_atto: {
    id: 'consulenza_data_atto',
    defaultMessage: "Data dell'atto",
  },
  estremi_atto: {
    id: 'consulenza_estremi_atto',
    defaultMessage: "Estremi dell'atto",
  },
  ufficio_responsabile_procedimento: {
    id: 'consulenza_ufficio_responsabile_procedimento',
    defaultMessage:
      'Ufficio e Funzionario/Dirigente responsabile del procedimento',
  },

  ulteriori_informazioni: {
    id: 'ulteriori_informazioni',
    defaultMessage: 'Ulteriori informazioni',
  },

  // documenti: {
  //   id: 'documenti',
  //   defaultMessage: 'Documenti',
  // },
});

/**
 * ConsulenzaView view component class.
 * @function ConsulenzaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const ConsulenzaView = ({ content }) => {
  const intl = useIntl();
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);

  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setSideMenuElements(documentBody.current);
      }
    }
  }, [documentBody]);

  return (
    <>
      <div className="container px-4 my-4 persona-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          imageinheader={true}
          imageinheader_field={'foto_persona'}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        <div className="row border-top row-column-border row-column-menu-left">
          <aside className="col-lg-4">
            <SideMenu data={sideMenuElements} />
          </aside>
          <section
            className="col-lg-8 it-page-sections-container"
            id="main-content-section"
            ref={documentBody}
          >
            {(content.intestatario ||
              content.modalita_individuazione_beneficiario ||
              content.norma_attribuzione) && (
              <RichTextArticle
                tag_id="beneficiario"
                title={intl.formatMessage(messages.beneficiario)}
              >
                {content?.intestatario && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.intestatario)}</h5>
                    {content?.intestatario}
                  </div>
                )}
                {content?.modalita_individuazione_beneficiario && (
                  <div className="mb-5 mt-3">
                    <h5>
                      {intl.formatMessage(
                        messages.modalita_individuazione_beneficiario,
                      )}
                    </h5>
                    {content?.modalita_individuazione_beneficiario.title}
                  </div>
                )}
                {content?.norma_attribuzione && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.norma_attribuzione)}</h5>
                    {content?.norma_attribuzione}
                  </div>
                )}
              </RichTextArticle>
            )}

            {(content.oggetto_rapporto ||
              content.data_inizio_incarico ||
              content.data_fine_incarico ||
              content.importo) && (
              <RichTextArticle
                tag_id="incarico"
                title={intl.formatMessage(messages.incarico)}
              >
                {content?.oggetto_rapporto && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.oggetto_rapporto)}</h5>
                    {content?.oggetto_rapporto}
                  </div>
                )}

                {(content?.data_inizio_incarico ||
                  content.data_fine_incarico) && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.durata_incarico)}</h5>
                    {content.data_inizio_incarico && (
                      <>
                        {intl.formatMessage(messages.durata_incarico_dal)}{' '}
                        {viewDate(
                          intl.locale,
                          content.data_inizio_incarico,
                          'DD/MM/YYYY',
                        )}{' '}
                      </>
                    )}
                    {content.data_fine_incarico && (
                      <>
                        {intl.formatMessage(messages.durata_incarico_al)}{' '}
                        {viewDate(
                          intl.locale,
                          content.data_fine_incarico,
                          'DD/MM/YYYY',
                        )}
                      </>
                    )}
                  </div>
                )}

                {content?.importo && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.importo)}</h5>
                    {content?.importo}
                  </div>
                )}
              </RichTextArticle>
            )}

            {(content.data_atto ||
              content.estremi_atto ||
              content.ufficio_responsabile_procedimento) && (
              <RichTextArticle
                tag_id="atto_e_procedimento"
                title={intl.formatMessage(messages.atto_e_procedimento)}
              >
                {content?.data_atto && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.data_atto)}</h5>
                    {viewDate(intl.locale, content.data_atto, 'DD/MM/YYYY')}
                  </div>
                )}

                {content?.estremi_atto && (
                  <div className="mb-5 mt-3">
                    <h5>{intl.formatMessage(messages.estremi_atto)}</h5>
                    {content?.estremi_atto}
                  </div>
                )}

                {content?.ufficio_responsabile_procedimento && (
                  <div className="mb-5 mt-3">
                    <h5>
                      {intl.formatMessage(
                        messages.ufficio_responsabile_procedimento,
                      )}
                    </h5>
                    {content?.ufficio_responsabile_procedimento.title}
                  </div>
                )}
              </RichTextArticle>
            )}

            {content.items?.length > 0 && (
              <Attachments
                items={content.items.filter((item) => item != null)}
                article_id={'allegati'}
              />
            )}

            <Metadata content={content}>
              {richTextHasContent(content?.ulteriori_informazioni) && (
                <HelpBox text={content?.ulteriori_informazioni} />
              )}
            </Metadata>
          </section>
        </div>
      </div>

      <RelatedItems content={content} />
      <RelatedItemInEvidence content={content} />
    </>
  );
};

export default ConsulenzaView;
