import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from 'design-react-kit/dist/design-react-kit';
import cx from 'classnames';

const NoCardTemplateSkeleton = ({
  title,
  isEditMode,
  linkHref,
  show_block_bg,
}) => {
  return (
    <div
      className={cx('card-with-side-image-template', {
        'public-ui': isEditMode,
      })}
    >
      <Container className="px-4 px-md-0">
        <div className="skeleton-template">
          {title && (
            <Row>
              <Col>
                <h2 className="mb-4">{title}</h2>
              </Col>
            </Row>
          )}
          <Row className="items">
            {[0, 1, 2, 3].map((i) => (
              <Col md="6" lg="4" key={i} className="col-item px-2">
                <Card
                  color=""
                  className="card-bg rounded"
                  noWrapper={false}
                  space
                  tag="div"
                >
                  <CardBody>
                    <CardTitle tag="h4">-</CardTitle>
                    <CardText tag="p"></CardText>
                  </CardBody>
                  <div className="image-container">
                    <figure className="img-wrapper"></figure>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
          {linkHref && <div className="link-more text-center my-4"></div>}
        </div>
      </Container>
    </div>
  );
};

NoCardTemplateSkeleton.propTypes = {
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default NoCardTemplateSkeleton;
