/**
 * AtletaView view component.
 * @module components/theme/View/AtletaView
 */

import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { readingTime } from 'design-comuni-plone-theme/components/ItaliaTheme/View/ViewUtils';
import { Card, CardTitle } from 'design-react-kit/dist/design-react-kit';
import {
  viewDate,
  contentFolderHasItems,
} from 'design-comuni-plone-theme/helpers';
import Image from '@plone/volto/components/theme/Image/Image';
import {
  Metadata,
  RichTextArticle,
  PageHeader,
  SideMenu,
  Gallery,
  Attachments,
  RelatedItems,
  RelatedItemInEvidence,
  richTextHasContent,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
  statistiche: {
    id: 'statistiche',
    defaultMessage: 'Statistiche',
  },
  multimedia_doc: {
    id: 'multimedia_doc',
    defaultMessage: 'Multimedia e documenti',
  },
  dati_anagrafici: {
    id: 'dati_anagrafici',
    defaultMessage: 'Dati anagrafici',
  },
  cognome_nome: {
    id: 'cognome_nome',
    defaultMessage: 'Cognome e nome',
  },
  grado: {
    id: 'grado',
    defaultMessage: 'Grado',
  },
  luogo_di_nascita: {
    id: 'luogo_di_nascita',
    defaultMessage: 'Luogo di nascita',
  },
  data_di_nascita: {
    id: 'data_di_nascita',
    defaultMessage: 'Data di nascita',
  },
  data_arruolamento: {
    id: 'data_arruolamento',
    defaultMessage: 'Data arruolamento',
  },
  stato_civile: {
    id: 'stato_civile',
    defaultMessage: 'Stato civile',
  },
  peso: {
    id: 'peso',
    defaultMessage: 'Peso',
  },
  specialita: {
    id: 'specialita',
    defaultMessage: 'Disciplina / specialità',
  },
});

/**
 * AtletaView view component class.
 * @function AtletaView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const AtletaView = ({ content, location }) => {
  const intl = useIntl();

  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);

  let related_items = [];
  if (content?.related_news?.length > 0) {
    related_items = [...related_items, ...content.related_news];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }

  useEffect(() => {
    if (documentBody.current) {
      if (__CLIENT__) {
        setReadingtime(
          readingTime(content.title, content.description, documentBody),
        );
        setSideMenuElements(documentBody.current);
      }
    }
  }, [content.description, content.title, documentBody]);

  return (
    <>
      <div className="container px-4 my-4 Storia-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={false}
          showdates={true}
          showtopics={true}
          showtassonomiaargomenti={true}
        />
        {content?.image && (
          <div className="header-atleta-image">
            <figure>
              <Image
                image={content.image}
                alt={content.title}
                className="img-fluid"
                maxSize={300}
              />
            </figure>
          </div>
        )}
        <div className="row border-top row-column-border row-column-menu-left">
          <aside className="col-lg-4">
            <SideMenu data={sideMenuElements} />
          </aside>
          <section
            className="col-lg-8 it-page-sections-container"
            id="main-content-section"
            ref={documentBody}
          >
            {/*** Disciplina ***/}
            {content?.specialita?.title && (
              <RichTextArticle
                tag_id="specialita"
                title={intl.formatMessage(messages.specialita)}
              >
                <Card
                  className="card card-teaser rounded shadow mt-3 mb-3"
                  noWrapper={true}
                  tag="div"
                >
                  <CardTitle tag="h5">
                    {content?.icona_disciplina?.length > 0 && (
                      <div className="icon-argument-container mr-3">
                        <Image
                          image={content.icona_disciplina[0]['@id']}
                          alt={content.specialita.title}
                        />
                      </div>
                    )}

                    {content.specialita.title}
                  </CardTitle>
                </Card>
              </RichTextArticle>
            )}

            {/*** Anagrafica ***/}
            {(content?.nome ||
              content?.cognome ||
              content?.grado?.title ||
              content?.data_di_nascita ||
              content?.luogo_di_nascita ||
              content?.stato_civile?.title ||
              content?.peso ||
              content?.data_arruolamento) && (
              <RichTextArticle
                tag_id="dati_anagrafici"
                title={intl.formatMessage(messages.dati_anagrafici)}
              >
                <div className="mt-3 mb-4">
                  <h5>{intl.formatMessage(messages.cognome_nome)}</h5>
                  {content.cognome} {content.nome}
                </div>

                {/* Grado */}
                {content?.grado?.title && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.grado)}</h5>
                    {content.grado.title}
                  </div>
                )}

                {/* Data di nascita */}
                {content?.data_di_nascita && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.data_di_nascita)}</h5>
                    {viewDate(
                      intl.locale,
                      content.data_di_nascita,
                      'DD-MM-YYYY',
                    )}
                  </div>
                )}

                {/* Luogo di nascita */}
                {content?.luogo_di_nascita && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.luogo_di_nascita)}</h5>
                    {content.luogo_di_nascita}
                  </div>
                )}

                {/* Stato civile */}
                {content?.stato_civile?.title && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.stato_civile)}</h5>
                    {content.stato_civile.title}
                  </div>
                )}

                {/* Peso */}
                {content?.peso && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.peso)}</h5>
                    {content.peso}
                  </div>
                )}

                {/* Data arruolamento */}
                {content?.data_arruolamento && (
                  <div className="mt-3 mb-4">
                    <h5>{intl.formatMessage(messages.data_arruolamento)}</h5>
                    {viewDate(
                      intl.locale,
                      content.data_arruolamento,
                      'DD-MM-YYYY',
                    )}
                  </div>
                )}
              </RichTextArticle>
            )}

            {/*** Statistiche ***/}
            {richTextHasContent(content.statistiche) && (
              <RichTextArticle
                content={content.statistiche}
                tag_id="statistiche"
                title={intl.formatMessage(messages.statistiche)}
              />
            )}

            {/*** Multimedia e documenti ***/}
            {(contentFolderHasItems(content, 'multimedia') ||
              contentFolderHasItems(content, 'documenti-allegati')) && (
              <RichTextArticle
                tag_id="multimedia_doc"
                title={intl.formatMessage(messages.multimedia_doc)}
              >
                <Gallery
                  content={content}
                  folder_name={'multimedia'}
                  title_type="h5"
                  className="mt-3"
                />

                <Attachments
                  content={content}
                  folder_name={'documenti-allegati'}
                  as_article={false}
                  title={intl.formatMessage(messages.attachments)}
                />
              </RichTextArticle>
            )}

            <Metadata content={content} />
          </section>
        </div>
      </div>

      <RelatedItems list={related_items} />
      <RelatedItemInEvidence content={content} />
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
AtletaView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    nome: PropTypes.string.isRequired,
    cognome: PropTypes.string.isRequired,
    image: PropTypes.object,
    image_caption: PropTypes.string,
    items: PropTypes.array,
    statistiche: PropTypes.shape({
      data: PropTypes.string,
    }),
    luoghi_correlati: PropTypes.array,
    related_news: PropTypes.array,
  }).isRequired,
};

export default AtletaView;
