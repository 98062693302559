/*CUSTOMIZATIONS:
- Mostrata l'icona in base al formato del file.
- Nascosta l'estensione del nome del file*/

import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardBody,
  CardTitle,
} from 'design-react-kit/dist/design-react-kit';
import { FontAwesomeIcon as IconFA } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from '@plone/volto/components';
import { getFileViewFormat } from 'design-comuni-plone-theme/helpers';

const Attachment = ({ title, description, download_url, item }) => {
  const defaultIcon = { lib: 'far', name: 'file', svg_format: false };

  let icon = defaultIcon;

  if (item) {
    const viewFormat = getFileViewFormat(
      item.image ?? item.file ?? { ...item, filename: item.path },
    );
    icon = viewFormat?.icon ?? defaultIcon;
  }

  const file_formats = [
    '.pdf',
    '.zip',
    '.gzip',
    '.rar',
    '.tar',
    '.json',
    '.js',
    '.jpg',
    '.png',
    '.svg',
    '.doc',
    '.docx',
    '.xls',
    '.xlsx',
    '.ppt',
    '.pptx',
    '.xml',
    '.txt',
    '.xsd',
    '.odt',
    '.ods',
    '.odp',
    '.xmp',
    '.cr2',
  ];
  let filename = title ?? item.title ?? item.filename;
  let filename_lc = filename.toLowerCase();
  file_formats.forEach((fm) => {
    if (filename_lc.endsWith(fm)) {
      filename = filename.substr(0, filename_lc.lastIndexOf(fm));
    }
  });

  return (
    <Card
      className="card card-teaser shadow p-4 mt-3 rounded attachment"
      noWrapper={true}
      tag="div"
    >
      {!icon.svg_format ? (
        <IconFA
          icon={[icon.lib, icon.name]}
          alt={filename}
          title={filename}
          size="2x"
          className="icon"
        />
      ) : (
        <Icon className="icon" name={icon.name} />
      )}
      <CardBody tag="div">
        <CardTitle tag="h5">
          <a href={flattenToAppURL(download_url)}>{filename}</a>
        </CardTitle>
        {description && <p>{description}</p>}
      </CardBody>
    </Card>
  );
};
Attachment.propTypes = {
  title: PropTypes.string,
  download_url: PropTypes.string,
};

export default Attachment;
