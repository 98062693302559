import React from 'react';
import PropTypes from 'prop-types';
import { UniversalLink } from '@plone/volto/components';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container,
  Row,
  Col,
} from 'design-react-kit/dist/design-react-kit';
import {
  getItemIcon,
  ListingCategory,
  ListingText,
  ListingLinkMore,
  CardCategory,
} from 'design-comuni-plone-theme/components/ItaliaTheme';
import { getCategory } from 'design-comuni-plone-theme/components/ItaliaTheme/Blocks/Listing/Commons/utils';

import { getCalendarDate } from 'design-comuni-plone-theme/helpers';

const NoCardTemplate = (props) => {
  const {
    items,
    title,
    description,
    isEditMode,
    linkTitle,
    linkHref,
    show_icon = true,
    show_section = true,
    show_type,
    show_description = true,
    hide_dates,
  } = props;

  return (
    <div className="no-card-template">
      <div className="full-width bg-primary">
        <Container className="px-4">
          {title && (
            <Row>
              <Col>
                <h2 className="mb-4">{title}</h2>
              </Col>
            </Row>
          )}

          {description && (
            <Row>
              <Col>
                <div className="description mb-4">{description}</div>
              </Col>
            </Row>
          )}

          <ListingLinkMore title={linkTitle} href={linkHref} className="my-5" />

          <Row className="items">
            {items.map((item, index) => {
              const icon = show_icon ? getItemIcon(item) : null;
              const date = !hide_dates ? getCalendarDate(item) : null;
              const category = getCategory(
                item,
                show_type,
                show_section,
                props,
              );
              const listingText = show_description ? (
                <ListingText item={item} />
              ) : null;

              return (
                <Col md="6" lg="4" key={item['@id']} className="col-item px-2">
                  <Card noWrapper={false} space tag="div">
                    <CardBody>
                      {(icon || category || date) && (
                        <CardCategory iconName={icon} date={date}>
                          {category && (
                            <span className="text font-weight-bold">
                              <ListingCategory
                                category={category}
                                item={item}
                              />
                            </span>
                          )}
                        </CardCategory>
                      )}
                      <CardTitle tag="h3">
                        <UniversalLink
                          item={!isEditMode ? item : null}
                          href={isEditMode ? '#' : null}
                        >
                          {item.title}
                        </UniversalLink>
                      </CardTitle>
                      {listingText && (
                        <CardText tag="p">{listingText}</CardText>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      </div>
    </div>
  );
};

NoCardTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
  linkTitle: PropTypes.any,
  linkHref: PropTypes.any,
  isEditMode: PropTypes.bool,
  title: PropTypes.string,
};

export default NoCardTemplate;
